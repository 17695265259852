<template>
  <!-- xlxs转换json -->
  <div>
    <h3>代购会员端语言包转换</h3>
    <input type="file" @change="uploadExcelFile" />
  </div>
</template>

<script>
import XLSX from "xlsx";
export default {
  data() {
    return {
      excelData: [],
      languageOBJS: {},
    };
  },
  created() {},
  methods: {
    async uploadExcelFile(event) {
      let file = event.target.files[0];
      if (!file || !/\.xlsx$/.test(file.name)) return;

      //   获取到一个数组,表格的每一行就是数租的一项
      let excelData = (await this.readExcelFile(file)) || [];
      console.log(excelData, "excelData");
      // return false;
      let keys = [];
      let codes = excelData[1];

      let listA = JSON.parse(JSON.stringify(excelData));

      listA.splice(0, 2); // 去掉表头和第一行code
      //   提取所属板块,去重
      listA.forEach((item, idx) => {
        if (keys.indexOf(item.A) == -1) {
          keys.push(item.A);
        }
      });

      for (var k in codes) {
        if (codes[k] == "*") {
          delete codes[k];
        }
      }

      let languageOBJS = {};
      keys.forEach((item2) => {
        languageOBJS[item2] = {};
      });
      // 组合自己想要的格式
      for (var a = 0; a < listA.length; a++) {
        for (var key in languageOBJS) {
          if (listA[a].A == key) {
            let objs = {};
            for (var k2 in codes) {
              objs[codes[k2]] = listA[a][k2] || "";
            }
            // let obj = {
            //   zh: listA[a].C || "",
            //   en: listA[a].D || "",
            //   id: listA[a].E || "",
            //   cht: listA[a].F || "",
            // };
            languageOBJS[key][listA[a].B] = objs;
          }
        }
      }

      //   最终得到想要的格式
      console.log("languageOBJS", languageOBJS);
      this.languageOBJS = languageOBJS;

      this.createJs(); //导出js
    },
    createJs() {
      let data = JSON.stringify(this.languageOBJS);
      let jsCode = `
        let data = ${data};
      export default data
      `;

      //   fs.writeFile("test.js", jsCode, function (err) {
      //     if (err) throw err;
      //     console.log("File created successfully!");
      //   });

      //   let jsCode = "let data = [1, 2, 3, 4, 5]; console.log(data);";
      let blob = new Blob([jsCode], { type: "text/javascript" });
      let url = URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = "test.js";
      document.body.appendChild(link);
      link.click();
    },

    readExcelFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          let workbook = XLSX.read(data, { type: "binary" });
          let sheetName = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[sheetName];
          let result = XLSX.utils.sheet_to_json(worksheet, { header: "A" });
          resolve(result);
        };
        reader.onerror = (e) => {
          reject(e);
        };
        reader.readAsBinaryString(file);
      });
    },
  },
};
</script>
